import dayjs from 'dayjs';
import Config from '@/config/config';
import { getAuth } from '../auth/auth.helpers';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listByMesRef = async (mesRef) => {
  var axios = require('axios');
  var data = { mesRef };

  const auth = getAuth();
  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_MANAGER}/aneel/bandeira/list`,
    headers: {
      Bearer: auth.token
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      Vue.$toast.success('Dados encontrados.', response.content);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
};
