<template>
  <div>
    <PageHeader
      :title="$t('backoffice.bandeira_tarifaria')"
      :items="breadcrumb"
    />

    <!-- Filtro -->
    <b-card class="mb-3 mt-3">
      <b-container fluid class="ml-3 mt-2">
        <b-row align-v="center" class="my-3">
          <!-- Mês Referência -->
          <b-col md="4">
            <b-form-group label="Mês Referência" label-for="filtroMesRef">
              <b-form-select
                id="filtroMesRef"
                v-model="filtroMesRef"
                :options="mesesReferencia"
                @change="filtrarBandeiras"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <!-- Ano Referência -->
          <b-col md="4">
            <b-form-group label="Ano Referência" label-for="filtroAnoRef">
              <b-form-input
                id="filtroAnoRef"
                type="number"
                v-model="filtroAnoRef"
                @change="filtrarBandeiras"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Botão Consultar Bandeiras Tarifárias -->
          <b-col md="4" class="d-flex justify-content">
            <b-button @click="buscarBandeiras()" variant="success" class="mt-2">
              Buscar
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Tabela de Bandeiras Tarifárias -->
    <b-card v-if="bandeiras.length" class="mb-3">
      <b-container fluid class="ml-3 mt-2">
        <b-row>
          <b-col>
            <h4 class="titulo mb-4">Bandeiras Tarifárias</h4>
            <b-table
              :items="bandeirasFiltradas"
              :fields="tableFields"
              striped
              hover
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :tbody-tr-class="rowClass"
            >
              <!-- Slot personalizado para a coluna 'mesRef' -->
              <template #cell(mesRef)="data">
                {{ formatarMes(data.item.mesRef) }}
              </template>
              <!-- Slot personalizado para a coluna 'id' -->
              <template #cell(id)="data">
                <b-link @click="navigateToEditPage(data.item)">
                  {{ data.item.id }}
                </b-link>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import Vue from 'vue';
import VueMask from 'v-mask';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';
import moment from 'moment';
import PageHeader from '@/components/page-header';
import 'moment/locale/pt-br';
import meses from '@/utils/meses';

import { listByMesRef } from './bandeirasTarifarias.service';

Vue.use(VueCookies);
Vue.use(VueToast);
Vue.use(moment);
Vue.use(VueMask);

export default {
  name: 'BandeiraTarifaria',
  components: {
    PageHeader,
    BFormSelect
  },
  data() {
    return {
      bandeiras: [],
      bandeirasFiltradas: [],
      mesesReferencia: meses(),
      anosReferencia: this.gerarListaAnos(),
      filtroAnoRef: new Date().getFullYear().toString(),
      coresBandeira: [],
      filtroMesRef: 'Todos',
      filtroCorBandeira: 'Todos',
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'corBandeira', label: 'Cor da Bandeira', sortable: true },
        { key: 'mesRef', label: 'Mês Referência', sortable: true },
        { key: 'valorBandeira', label: 'Valor (R$/MWh)', sortable: true }
      ]
    };
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: 'Backoffice',
          href: '/'
        },
        {
          text: 'Bandeira Tarifária',
          active: true
        }
      ];
    }
  },
  methods: {
    navigateToEditPage(bandeiraTarifaria) {
      if (bandeiraTarifaria === null) {
        this.$router.push({
          path: '/edit-bandeiras-tarifarias'
        });
      } else {
        this.$router.push({
          path: '/edit-bandeiras-tarifarias',
          query: {
            bandeira: JSON.stringify(bandeiraTarifaria)
          }
        });
      }
    },
    formatarMes(mesRef) {
      return moment(mesRef, 'YYYY-MM').locale('pt-br').format('MMMM YYYY');
    },
    gerarListaAnos() {
      const anoAtual = new Date().getFullYear();
      let anos = [];
      for (let i = anoAtual - 10; i <= anoAtual; i++) {
        anos.push(i);
      }
      return anos;
    },
    buscarBandeiras() {
      const mesRefFormatado = `${this.filtroAnoRef}-${String(
        this.filtroMesRef
      ).padStart(2, '0')}-01`;
      listByMesRef(mesRefFormatado).then((bandeiras) => {
        this.bandeiras = bandeiras.bandeiraTarifaria;
        this.filtrarBandeiras();

        // Usar o método calcularUltimos12Meses para obter os meses
        const ultimos12Meses = this.calcularUltimos12Meses();

        this.mesesReferencia = [
          { text: 'Todos', value: 'Todos' }, // Adicionando opção 'Todos'
          ...ultimos12Meses.map((mes) => ({
            text: moment(mes, 'YYYY-MM').format('MMMM YYYY'),
            value: mes
          }))
        ];

        // Preencher as cores da bandeira
        const cores = this.bandeiras.reduce((acc, bandeira) => {
          acc[bandeira.corBandeira] = true;
          return acc;
        }, {});

        this.coresBandeira = [
          { text: 'Todos', value: 'Todos' }, // Adicionando opção 'Todos'
          ...Object.keys(cores).map((cor) => ({
            text: cor,
            value: cor
          }))
        ];
      });
    },
    filtrarBandeiras() {
      this.bandeirasFiltradas = this.bandeiras.filter((bandeira) => {
        const mesRefMatch =
          this.filtroMesRef === 'Todos' ||
          bandeira.mesRef.includes(this.filtroMesRef);
        const corBandeiraMatch =
          this.filtroCorBandeira === 'Todos' ||
          bandeira.corBandeira === this.filtroCorBandeira;

        return mesRefMatch && corBandeiraMatch;
      });
    },
    calcularUltimos12Meses() {
      const hoje = moment();
      const meses = [];

      for (let i = 0; i < 12; i++) {
        meses.push(hoje.subtract(i, 'months').format('YYYY-MM-01'));
      }

      return meses;
    }
  },
  mounted() {
    moment.locale('pt-br');
  }
};
</script>

<style>
.titulo {
  text-align: center;
  margin-top: 20px;
}
</style>
